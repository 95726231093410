import { mutateOperations } from "@/api/operations/useOperationsQuery";
import { sites } from "@/api/sites/useSitesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiOperationsOperationIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export function OperationsUpdateForm(params: { formatterProps: any }) {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      siteCode: formatterProps.siteCode,
      fromLocationCode: formatterProps.fromLocationCode,
      toLocationCode: formatterProps.toLocationCode,
    },
  });

  const { mutate: updateMutate } = useMutation(
    (params: DefaultApiOperationsOperationIdPutRequest) =>
      mutateOperations.update(params).mutationFn(params as DefaultApiOperationsOperationIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['operations']);
        closeModal(formatterProps);
              customAlert(
                "공정 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
      },
      onError: (error) => {
        customAlert(handleErrorResponse({ error, errorContext: '공정 코드' }), '공정 수정 실패', 'red')
      }
    }
  );

  const { data } = useQuery(sites.get({}));
  // const { data } = useWorkSitesQuery();

  const siteData = data?.data?.rows as any[];

  const siteCode =
    (siteData &&
      (siteData?.map((value) => {
        return value.code;
      }) as any)) ||
    [];

  const onSubmit = async () => {
    try {
      updateMutate(
        {
          operationId: formatterProps.id as number,
          operationsGetRequest: {
            siteCode: form.values.siteCode as any,
            code: form.values.code as any,
            name: form.values.name as any,
            fromLocationCode: form.values.fromLocationCode as any,
            toLocationCode: form.values.toLocationCode as any,
          },
        },
      );
    } catch (error) {
      customAlert(handleErrorResponse({ error, errorContext: '공정 코드' }), '공정 수정 실패', 'red')
    }
  };

  return (
    <Box maw={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            withAsterisk
            label="공정코드"
            placeholder="code"
            defaultValue={formatterProps.code}
            {...form.getInputProps("code")}
            error={form.isDirty("code") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("code")
                  ? "red !important"
                  : "",
                borderColor: form.isDirty("code")
                  ? "red !important"
                  : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <TextInput
            withAsterisk
            label="공정코드"
            placeholder="name"
            defaultValue={formatterProps.name}
            {...form.getInputProps("name")}
            error={form.isDirty("name") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("name")
                  ? "red !important"
                  : "",
                borderColor: form.isDirty("name")
                  ? "red !important"
                  : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <SitesAutoComplete
            withAsterisk
            label="사업장코드"
            width={"100%"}
            data={siteCode}
            defaultValue={formatterProps.siteCode}
            {...form.getInputProps("siteCode")}
            error={form.isDirty("siteCode") ? "저장 필요" : ""}
          />
          <LocationAutoComplete
            withAsterisk
            label="자재 출고 창고"
            width={"100%"}
            defaultValue={formatterProps.fromLocationCode}
            {...form.getInputProps("fromLocationCode")}
            error={form.isDirty("fromLocationCode") ? "저장 필요" : ""}
          />
          <LocationAutoComplete
            withAsterisk
            label="제공품 입고 창고"
            width={"100%"}
            defaultValue={formatterProps.toLocationCode}
            {...form.getInputProps("toLocationCode")}
            error={form.isDirty("toLocationCode") ? "저장 필요" : ""}
          />

          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "operations",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              공정 수정
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
}

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
