import { mutateInvoice } from "@/api/invoiceStatement/useInvoiceQuery";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { OrderDetailForm } from "@/features/order/form/detailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { flexGrowable, flexStable, StandardLayoutTableWrapper } from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { useLoader } from "@/hooks/useLoader";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { handleErrorResponse } from "@/utils/errorMessage";
import { purchaseOrderStatusColor, setPurchaseOrderStatus } from "@/utils/scmDataStatus";
import timeUtil from "@/utils/timeUtil";
import { Avatar, Badge, Button, Flex, Text, Tooltip } from "@mantine/core";
import { DefaultApiWmsInvoicePostRequest, InvoicesGet200Response, InvoicesGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus, IconMinus } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Column, SelectColumn } from "react-data-grid";

export const InvoiceStatementPage = () => {

    const { openModal, closeModal } = useModal();
    const queryClient = useQueryClient();
    const { selectedRows, setSelectedRows } = useStandardLayout();
    const { setLoading, LoadingOverlay } = useLoader();
    const { mutate: invoiceMutate } = useMutation(
        (params: DefaultApiWmsInvoicePostRequest) =>
            mutateInvoice.invoice(params).mutationFn(params as DefaultApiWmsInvoicePostRequest | any),
        {
            onMutate: () => {
                setLoading(true);
            },
            onSuccess: () => {
                setLoading(false);
                queryClient.invalidateQueries(["invoice"]);
                closeModal(null);
                setSelectedRows(new Set());
                customAlert(
                    "거래명세서 출고에 성공하였습니다.",
                    "거래명세서 출고 성공",
                    "green"
                )
            },
            onError: (error: any) => {
                setLoading(false);
                customAlert(
                    handleErrorResponse({ error, errorContext: "거래명세서 출고" }), "거래명세서 출고 실패", "red"
                )
            }
        }
    )

    const invoiceOutgoingSubmit = () => {
        openModal(
            <ConfirmForm
                message="선택한 거래명세서를 출고하시겠습니까?"
                yesCallback={() => {
                    [...selectedRows].map((row => {
                        invoiceMutate({
                            wmsInvoicePostRequest: {
                                invoiceNo: JSON.parse(row).invoiceNo,
                                invoiceItemIds: JSON.parse(row).invoiceItemIds
                            }
                        })
                    }))
                }}
                noCallback={() => { closeModal(null)}}
                commonCallback={() => {}}
            />,
            null,
            "거래명세서 출고"
        )
    }

    const statusDone = [...selectedRows].some(row => {
        const parsedRow = JSON.parse(row);
        return parsedRow.status === "DONE";
    });

    const columns: readonly Column<InvoicesGet200ResponseRowsInner>[] = [
        {
            ...SelectColumn,
            width: 70,
            maxWidth: 500,
            resizable: true,
        },
        {
            key: "id",
            name: '거래명세서 ID',
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <Flex h="100%" justify="flex-end" align="center">
                        <LinkAnchor
                            onClick={() => openModal(
                                <OrderDetailForm purchaseOrderId={formatterProps.row.id} />, null, ''
                            )}
                        >
                            {formatterProps.row.id}
                        </LinkAnchor>
                    </Flex>
                )
            }
        },
        {
            key: "code",
            name: "거래명세서 코드",
            sortable: true,
            resizable: true,
        },
        {
            key: "status",
            name: '거래명세서 상태',
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <Badge
                        variant="filled"
                        color={purchaseOrderStatusColor[formatterProps.row.status as keyof typeof purchaseOrderStatusColor]}
                    >{setPurchaseOrderStatus(formatterProps.row.status ?? '')}</Badge>
                )
            },
        },
        {
            key: "numberOfInvoiceItems",
            name: "거래명세서 갯수",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                const numberOfInvoiceItems = row?.numberOfInvoiceItems ?? 0;
                return <Flex justify="flex-end">{numberOfInvoiceItems}</Flex>
            },
        },
        {
            key: "locationName",
            name: "출고 로케이션 명",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <LinkAnchor
                        onClick={() => openModal(
                            <LocationDetailForm locationCode={formatterProps?.row?.locationCode} />,
                            null,
                            "로케이션 상세"
                        )}
                    >{formatterProps.row.locationName}</LinkAnchor>
                )
            },
        },
        {
            key: "invoiceDate",
            name: "거래명세서일",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <div>
                        {formatterProps.row.invoiceDate !== null ? timeUtil(formatterProps.row.invoiceDate as string) : ''}
                    </div>
                )
            },
        },
        {
            key: "createdAt",
            name: "접수일",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <div>
                        {formatterProps.row.createdAt !== null ? DatetimeUtil(formatterProps.row.createdAt as string) : ''}
                    </div>
                )
            },
        },
        {
            key: "creatorUserName",
            name: "작성자",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <LinkAnchor
                        onClick={() => openModal(
                            <UsersDetailForm UserCode={formatterProps.row?.creatorUserCode} />,
                            null,
                            "사용자 상세"
                        )}
                    >{formatterProps.row.creatorUserName}</LinkAnchor>
                )
            },
        }
    ];

    return (
        <Flex w="100%" h="100%" gap="xs" direction="column">
            <LoadingOverlay />
            <Flex p="sm" justify="space-between" rowGap="md">
                <Flex gap="sm">
                    <Button
                        leftIcon={<IconMinus />}
                        color="red"
                        onClick={invoiceOutgoingSubmit}
                        disabled={!([...selectedRows].length === 1) || statusDone}
                    >
                        출고
                    </Button>
                </Flex>
            </Flex>
            <Flex bg="white" px="sm" direction="column" gap="xs">
                <Text fz="xl" fw={600}>거래명세서</Text>
                <Text fz="xs">고객으로부터의 주문한 내역</Text>
            </Flex>
            <Flex justify="space-between" align="flex-start" px="sm">
                <Flex wrap="wrap" styles={flexGrowable}>
                    <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
                        <Tooltip label={"사용자 정의 필터입니다. \"필터 추가\" 또는 \"정렬 추가\" 버튼을 클릭하여 조건을 입력합니다."}>
                            <Avatar color="blue" variant="outline">
                                <IconFilterPlus />
                            </Avatar>
                        </Tooltip>
                        <Flex direction="column" gap="xs" wrap="wrap">
                            <Filter />
                            <Sorter />
                        </Flex>
                    </Flex>
                </Flex>
                <Flex styles={flexStable}>
                    <SearchBox />
                </Flex>
            </Flex>
            <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
                <StandardLayoutTableWrapper>
                    <ListTable<InvoicesGet200Response, InvoicesGet200ResponseRowsInner> columns={columns} rowKeyGetter={(row: InvoicesGet200ResponseRowsInner) => JSON.stringify(row)} />
                </StandardLayoutTableWrapper>
                <PaginationBox<InvoicesGet200Response> />
            </Flex>
        </Flex>
    )
}