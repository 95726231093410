import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import timeUtil from "@/utils/timeUtil";
import styled from "@emotion/styled";
import { Radio, Table, Text, Title } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { TableContainer } from "../../components/ledgerTracking";
import { useLotTrackingActions } from "../../hook/useLotTrackingActions";
import { useLotTrackingState } from "../../hook/useLotTrackingState";

const Detail = Object.assign(DetailHeader, {
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
})

interface LotTrackingSummaryContentProps {
    summaryData?: (AxiosResponse<WorkLogsGet200ResponseRowsInner[], any> | undefined)[];
}

export const LotTrackingForwardSummaryContent = (params: LotTrackingSummaryContentProps) => {
    const { summaryData } = params
    const navigage = useNavigate();
    const { openModal } = useModal();
    const actions = useLotTrackingActions();
    const state = useLotTrackingState();

    const item = Array.from(
        new Set(
            summaryData
                ?.flatMap(response => response?.data ?? [])
                ?.map(row => row)
        )
    );

    return (
        <>
            <Container>
                <Title p={8} order={3}>요약</Title>
                <Text
                    fz={12}
                    px={8}
                >
                    각 라우팅에 투입된 원부자재의 로트를 추적합니다.
                </Text>
                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th flex={0.1}> </Th>
                                <Th flex={1}>품목</Th>
                                <Th flex={1}>로트명</Th>
                                <Th flex={1}>로트 유효기한</Th>
                                <Th flex={1}>생산계획</Th>
                                <Th flex={1}></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {item?.filter((value, index, self) =>
                                index === self.findIndex((t) => t.productionPlanId === value.productionPlanId)
                            )?.map((item, index) => {
                                const handleRadioClick = (plan: WorkLogsGet200ResponseRowsInner) => {
                                    actions.setProductionPlan(plan);
                                };
                                return (
                                    <Tr key={index}>
                                        <Td flex={0.1}>
                                            <Radio
                                                name="productionPlan"
                                                value={item.productionPlanId}
                                                checked={state.productionPlan?.productionPlanId === item.productionPlanId}
                                                onChange={() => handleRadioClick(item)}
                                            />
                                        </Td>
                                        <Td flex={1}>
                                            <Detail.LinkWrapper>
                                                <Detail.Badge>품목</Detail.Badge>
                                                <Detail.Link
                                                    onClick={() => openModal(
                                                        <ItemsDetailForm itemCode={item.itemData?.code} />,
                                                        "",
                                                        ""
                                                    )}
                                                >
                                                    {item.itemData?.code}
                                                </Detail.Link>
                                            </Detail.LinkWrapper>
                                        </Td>
                                        <Td flex={1}>
                                            {item.lotData?.name ?? '-'}
                                        </Td>
                                        <Td flex={1}>
                                            {item.lotData?.expiration === null ? '-' : timeUtil(item.lotData?.expiration ?? '')}
                                        </Td>
                                        <Td flex={1}>
                                            <Detail.LinkWrapper>
                                                <Detail.Badge>생산계획</Detail.Badge>
                                                <Detail.Link
                                                    onClick={() => openModal(
                                                        <ProductionPlanViewForm ProductionPlanId={item?.productionPlanId} />,
                                                        "",
                                                        ""
                                                    )}
                                                >
                                                    {item.productionPlanId}
                                                </Detail.Link>
                                            </Detail.LinkWrapper>
                                        </Td>
                                        <Td flex={1}>
                                            <Detail.LinkWrapper>
                                                <Detail.Link
                                                    onClick={() => navigage('/lotReverce')}>
                                                    역방향 로트 추적 바로가기
                                                </Detail.Link>
                                            </Detail.LinkWrapper>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export const Container = styled.div`
    display: flex;
    height: 334px;
    padding: 8px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    background: var(--pink-0, #FFF0F6);
    overflow: auto;
`;

export const Thead = styled.thead`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const Tr = styled.tr`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;
`;

export const Th = styled.th<{ flex?: number }>`
  display: flex;
  height: 40px;
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
  flex: ${(props) => (props.flex ? props.flex : `1 0 0`)};
`;

export const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const Td = styled.td<{ flex?: number }>`
  padding: 7px 10px;
  height: 70px;
  align-items: flex-start;
  gap: 10px;
  word-break: break-word;
  flex: ${(props) => (props.flex ? props.flex : `1 0 0`)};

`;